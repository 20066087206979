var constants = {

	modules: {

		usps: {

			sliderMaxWidth: 979

		}

	}

};



